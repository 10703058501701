import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'
import loadScript from "../components/loadScript"
import { graphql } from 'gatsby'
import FeaturedListingProperties from "../components/index/FeaturedListingProperties/FeaturedListingProperties"
import SearchPropertyFilter from "../components/index/SearchPropertyFilter/SearchPropertyFilter"
import '../css/index.css';

import { injectIntl, Link } from "gatsby-plugin-intl";
import ServicesComp from '../components/servicesComp';
import CookieConsent from "react-cookie-consent";
import Header from '../components/header';

import propertiesData from "../../build_data/properties.json"

class IndexPage extends React.Component {

  loadPopularPlacesScript() {    
    var self = this;
    loadScript(document.location.origin + '/theme/output/bundle.js')
    .then((script) => {
        self.loadPopularPlaces();
    })
    .catch((err) => {
        console.error(err.message);
    });
  };

  loadPopularPlaces(){
    var window_w = window.$(window).innerWidth();
    window.$('.gallery').find('.gallery-item').each(function() {
      var pi_height1 = window.$(this).outerWidth(true),
      pi_height2 = pi_height1/2;
      
      if(window.$(this).hasClass('grid-long') && window_w > 991){
        window.$(this).css('height', pi_height2);
      }else{
        window.$(this).css('height', Math.abs(pi_height1));
      }
    });
    window.$('.gallery').masonry({
      itemSelector: '.gallery-item',
        columnWidth: '.grid-sizer',
      gutter: 20
    });
  };

  componentDidMount(){   
      if (!window.$) {
        this.loadPopularPlacesScript();
      }
      else{
        this.loadPopularPlaces();
      }
  };

  render(){
    var golfProperties = propertiesData.filter(p => p.place === "Golf").length;
    var coastProperties = propertiesData.filter(p => p.place === "Coast").length;
    var mountainProperties = propertiesData.filter(p => p.place === "Mountain").length;
    var townProperties = propertiesData.filter(p => p.place === "Town").length;

    return(
      <Layout isMain={true}>
        <SEO 
          title={this.props.intl.formatMessage({ id: 'SEO-pageTitle' })}
          description={this.props.intl.formatMessage({ id: 'SEO-description' })}
          keywords={[
            this.props.intl.formatMessage({ id: 'SEO-keyword1' }),
            this.props.intl.formatMessage({ id: 'SEO-keyword2' }),
            this.props.intl.formatMessage({ id: 'SEO-keyword3' }),
            this.props.intl.formatMessage({ id: 'SEO-keyword4' })
          ]}
        />
        <section>
            <CookieConsent
                location="bottom"
                buttonText="OK"
                cookieName="VisaVerdeCookie"
                style={{ background: "#26583d", opacity: 0.9, fontSize: "13px" }}
                buttonStyle={{ background: '#333', color: "#fff", fontSize: "13px" }}
                expires={1}
            >
              {this.props.intl.formatMessage({ id:'CookiesAdvice1'})} {" "} {this.props.intl.formatMessage({ id:'CookiesAdvice2'})} 
              {this.props.intl.formatMessage({ id:'SeeOur'})} <a href="/cookies-policy" style={{ color: '#20e079' }}>{this.props.intl.formatMessage({ id:'CookiesPolicy'})}</a>
            </CookieConsent>
              <BackgroundImage Tag="section"
                fluid={this.props.data.placeholderImage.childImageSharp.fluid}
                className="hero-section"
              >
                <Header isIndex={true} intl={this.props.intl} />
              </BackgroundImage>

            {/* FILTER */}
            <SearchPropertyFilter></SearchPropertyFilter>

            {/* FEATURED LISTING SECTION */}
            <FeaturedListingProperties intl={this.props.intl}></FeaturedListingProperties>

              {/* PROPERTIES FEATURES SECTION */}
              <section className="feature-category-section spad">
                <div className="container">
                  <div className="section-title text-center">
                    <h3>{this.props.intl.formatMessage({ id:'Finding-property'})}</h3>
                    <p>{this.props.intl.formatMessage({ id:'Finding-property-subtitle'})}</p>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-6 f-cata">
                      <Link to={"/property-sales/#extras=new_development"} >
                        <Img fluid={this.props.data.featureCate1.childImageSharp.fluid}></Img>
                        <h5>{this.props.intl.formatMessage({ id:'New-construction'})}</h5>
                      </Link>
                    </div>
                    <div className="col-lg-3 col-md-6 f-cata">
                      <Link to={"/property-sales/#extras=is_luxury"} >
                        <Img fluid={this.props.data.featureCate2.childImageSharp.fluid}></Img>
                        <h5>{this.props.intl.formatMessage({ id:'Luxury-properties'})}</h5>
                      </Link>
                    </div>
                    <div className="col-lg-3 col-md-6 f-cata">
                      <Link to={"/property-sales/#extras=is_investment"} >
                        <Img fluid={this.props.data.featureCate3.childImageSharp.fluid}></Img>
                        <h5>{this.props.intl.formatMessage({ id:'Investments'})}</h5>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-6 f-cata">
                      <Link to={"/property-sales/#extras=is_re_sale"} >
                        <Img fluid={this.props.data.featureCate4.childImageSharp.fluid}></Img>
                        <h5>{this.props.intl.formatMessage({ id:'Re-sale'})}</h5>
                      </Link>
                    </div>
                  </div>
                </div>
              </section>


              {/* SERVICES SECTION */}
              <ServicesComp intl={this.props.intl}></ServicesComp>



              {/* POUPLAR PLACES SECTION */}
              <section className="gallery-section spad">
                <div className="container">
                  <div className="section-title text-center">
                    <h3>{this.props.intl.formatMessage({ id:'Popular-places'})}</h3>
                    <p>{this.props.intl.formatMessage({ id:'Popular-places-subtitle'})}</p>
                  </div>
                  <div className="gallery">
                    <div className="grid-sizer"></div>

                    <Link to={"/property-sales/#extras=Golf"} >
                      <BackgroundImage Tag="div"
                        fluid={this.props.data.popularPlace1.childImageSharp.fluid}
                        className="gallery-item grid-long"
                      >
                        <div className="gi-info">
                            <div className="box">
                                <h3>                               
                                  {this.props.intl.formatMessage({ id:'Golf'})}
                                </h3>
                                <p  className="popular-places-title" style={{display: golfProperties > 0  ? "block" : "none"}}>
                                  {golfProperties} {this.props.intl.formatMessage({ id:'Properties'})}
                                </p>
                            </div>
                        </div>
                      </BackgroundImage>
                    </Link>

                    <Link to={"/property-sales/#extras=Coast"} >
                      <BackgroundImage Tag="div"
                        fluid={this.props.data.popularPlace2.childImageSharp.fluid}
                        className="gallery-item grid-wide"
                      >
                        <div className="gi-info">
                          <div className="box">
                              <h3>
                                {this.props.intl.formatMessage({ id:'Coast'})}
                              </h3>
                              <p className="popular-places-title" style={{display: coastProperties > 0  ? "block" : "none"}}>
                                {coastProperties} {this.props.intl.formatMessage({ id:'Properties'})}
                              </p>
                          </div>
                        </div>
                      </BackgroundImage>
                    </Link>

                    <Link to={"/property-sales/#extras=Mountain"} >
                      <BackgroundImage Tag="div"
                        fluid={this.props.data.popularPlace3.childImageSharp.fluid}
                        className="gallery-item"
                      >
                        <div className="gi-info">
                            <div className="box">
                                <h3>                               
                                  {this.props.intl.formatMessage({ id:'Mountain'})}
                                </h3>
                                <p className="popular-places-title" style={{display: mountainProperties > 0  ? "block" : "none"}}>
                                  {mountainProperties} {this.props.intl.formatMessage({ id:'Properties'})}
                                </p>
                            </div>
                        </div>
                      </BackgroundImage>
                    </Link>


                    <Link to={"/property-sales/#extras=Town"} >
                    <BackgroundImage Tag="div"
                      fluid={this.props.data.popularPlace5.childImageSharp.fluid}
                      className="gallery-item"
                    >
                      <div className="gi-info">
                          <div className="box"> 
                              <h3>                               
                                {this.props.intl.formatMessage({ id:'Town'})}
                              </h3>        
                              <p className="popular-places-title" style={{display: townProperties > 0  ? "block" : "none"}}>
                                {townProperties} {this.props.intl.formatMessage({ id:'Properties'})}
                              </p>
                          </div>
                      </div>
                    </BackgroundImage>
                  </Link> 
                  </div>
                </div>
              </section>

              <div className="site-breadcrumb">
                  <div className="container">		
                  </div>
              </div>

            </section>  
      </Layout>
      )}
  }

export default injectIntl(IndexPage)

export const query = graphql`
query {
  logo: file(relativePath: { eq: "LogoRedondo.png" }) {
    childImageSharp {
        fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
        }
    }
  },
  placeholderImage: file(relativePath: { eq: "visaverde-main.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 3000) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  featureCate1: file(relativePath: { eq: "feature-cate/1.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  featureCate2: file(relativePath: { eq: "feature-cate/2.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  featureCate3: file(relativePath: { eq: "feature-cate/3.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  featureCate4: file(relativePath: { eq: "feature-cate/4.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  popularPlace1: file(relativePath: { eq: "popular-places/1.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  popularPlace2: file(relativePath: { eq: "popular-places/2.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 700) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  popularPlace3: file(relativePath: { eq: "popular-places/3.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  popularPlace5: file(relativePath: { eq: "popular-places/4.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`